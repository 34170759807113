import { useState } from "react";
import axios from "axios";
import styles from './ForgotPassword.module.scss'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post(`${API_URL}/auth/forgot-password`, { email })
      .then((response) => {
        setMessage("Email sent, please check your inbox.");
      })
      .catch((error) => {
        setMessage("Error sending email.");
      });
  };

  return (
    <div className={styles.forgot}>
      <h1 className={styles.forgoth1}>Mot de passe Oublié ?</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label className={styles.labelforgot}>Email:</label>
        <input type="email" value={email} onChange={handleEmailChange} required />
        <button className={styles.buttonforgot} type="submit">Envoi du lien</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}
