import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth.context'; // Assure-toi que le chemin est correct
import styles from './RenfoDetails.module.scss';
import Lesson from "../../data/renfo.json";
import LessonCard from '../../components/LessonCard/LessonCard';

export default function RenfoDetails({ lessonToDisplay, setLessonToDisplay }) {
  const { id } = useParams();
  const lesson = Lesson.find((lesson) => lesson.id === parseInt(id)); 
  const [randomLessons, setRandomLessons] = useState([]);

  // Récupérer l'utilisateur connecté via AuthContext
  const { user } = useContext(AuthContext);

  // Vérifier si l'utilisateur et l'abonnement existent
  const hasSubscription = user && user.subscription;

  // Initialiser les valeurs par défaut pour les dates si elles existent
  const startDate = hasSubscription && user.subscription.startDate ? new Date(user.subscription.startDate) : null;
  const endDate = hasSubscription && user.subscription.endDate ? new Date(user.subscription.endDate) : null;
  const now = new Date();

  // Vérifier si l'abonnement est actif
  const isSubscriptionActive = hasSubscription && user.subscription.isActive && now >= startDate && now <= endDate;

  // Ajouter une condition pour afficher les vidéos avec ID 40 et 120 même si l'abonnement n'est pas actif
  const canViewVideo = isSubscriptionActive 

  useEffect(() => {
    // Filtrer la leçon actuelle de la liste
    const otherLessons = Lesson.filter(l => l.id !== parseInt(id));

    // Mélanger les leçons restantes et en sélectionner 4
    const shuffledLessons = otherLessons.sort(() => 0.5 - Math.random());
    setRandomLessons(shuffledLessons.slice(0, 4));
  }, [id]);

  if (!lesson) {
    return <p>Leçon non trouvée.</p>;
  }

  return (
    <div className={styles.lessonDetails}>
      <h1 className={styles.h1}>{lesson.title}</h1>
      
      <div className={styles.videoProf}>
        <div>
          {canViewVideo ? (
            <div className={styles.videoContainer}>
              <iframe
                src={lesson.video_link}
                className={styles.video}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title={lesson.title}
              ></iframe>
            </div>
          ) : (
            <p className={styles.abonnement}>Votre abonnement n'est pas actif. Veuillez vous abonner ou renouveler l'abonnement pour accéder à la vidéo.</p>
          )}
        </div>
        <div className={styles.prof}>
          <div className={styles.profBanner}>
            <img className={styles.Thiago} src="/kine3.jpeg" alt="Thiago" />
            <p className={styles.name}>Félicien de Boerdere</p>
          </div>
          <p className={styles.info1}><i class="fa-solid fa-medal"></i>Renforcement</p>
          <p className={styles.info}><i class="fa-solid fa-circle-play"></i>55 vidéos</p>
          <p className={styles.info}><i class="fa-solid fa-users-line"></i>Tous niveaux</p>
          <div className={styles.buttonContainer}>
            <Link to="/professorJJB1">
              <button className={styles.linkButton}>Voir les détails</button>
            </Link>
          </div>
        </div>
      </div>

      <h2 className={styles.descriptionTitle}>Au programme de ce cours en ligne</h2>
      <p className={styles.description}>{lesson.description}</p>

      {/* Afficher lessonPrice uniquement si l'abonnement n'est pas actif */}
      {!isSubscriptionActive && lesson.id !== 40 && lesson.id !== 120 && (
        <div className={styles.lessonPrice}>
          <div className={styles.priceContainer}>
            <h3 className={styles.price}>à partir de <span>7,50€ </span>/ mois</h3>
          </div>
          <div className={styles.textContainer}>
            <h2 className={styles.title}><i className="fa-solid fa-check"></i>Des cours en ligne pour tous les niveaux.</h2>
            <h2 className={styles.title}><i className="fa-solid fa-check"></i>Accès illimité, 24h/24, 7j/7.</h2>
            <h2 className={styles.title}><i className="fa-solid fa-check"></i>Disponible sur ordinateur, tablette, smartphone.</h2>
            <h2 className={styles.title}><i className="fa-solid fa-check"></i>100% de liberté : vous pouvez résilier quand vous voulez.</h2>
            <Link to="/offers"><button className={`btn-cours ${styles.btncours}`}>M'abonner</button></Link>
          </div>
        </div>
      )}

      <div className={styles.recommanded}>
        <h2 className={styles.recommandedTitle}>Les apprenants ayant suivi ce cours de JJB s'intéressent aussi</h2>
        <div className={styles.recommendedLessons}>
          {randomLessons.map((randomLesson) => (
            <LessonCard 
              key={randomLesson.id} // Ajoutez une clé unique pour chaque élément
              lesson={randomLesson} // Passez la leçon aléatoire au lieu de `lesson`
              setLessonToDisplay={setLessonToDisplay} 
              basePath="renforcement" 
            />
          ))}
        </div>
      </div>
    </div>
  );
}

