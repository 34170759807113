
import styles from './Error.module.scss';


export default function Error() {
  
  return (
    <div className={styles.errorPage}>
        <h1 className={styles.error}>Votre paiment n'a pas abouti ! Veuillez réessayer ! </h1>
    </div>
    
  )
}