import React from 'react';
import { Link } from 'react-router-dom';
import styles from "./LessonCard.module.scss";

export default function LessonCard({ lesson, setLessonToDisplay, basePath }) {
  const handleLinkClick = () => {
    setLessonToDisplay(lesson); // Met à jour correctement l'état
    document.activeElement.blur();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Forcer la page en haut
    }, 100);
  };

  return (
    <Link 
      to={`/${basePath}/${lesson.id}`} 
      onClick={handleLinkClick} 
      className={styles.lessonCard}
    >
      <div className={styles.imageContainer}>
        <img src={lesson.image} alt={lesson.title} className={styles.image} />
      </div>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{lesson.title}</h3>
        <p className={styles.professor}>par <span>{lesson.professor}</span></p>
        <div className={styles.divTime}>
          <i className={`fa-solid fa-clock ${styles.fa}`}></i>
          <p className={styles.time}>{lesson.duration}</p>
          <p className={styles.level}>{lesson.level}</p>
          <p className={styles.category}>/{lesson.category}</p>
        </div>
      </div>
    </Link>
  );
}
