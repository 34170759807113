
import styles from './Success.module.scss';
import { useEffect, useContext } from "react";
import { AuthContext } from "../../context/auth.context"; // Importer le contexte de l'authentification
import { useNavigate } from "react-router-dom";

export default function Success() {
  const { authenticateUser } = useContext(AuthContext); // Récupérer la fonction d'authentification
  const navigate = useNavigate();

  useEffect(() => {
    const updateUserStatus = async () => {
      await authenticateUser(); // Mettre à jour l'état de l'utilisateur
    };

    updateUserStatus(); // Appeler la fonction pour mettre à jour
  }, [authenticateUser]);

  return (
    <div className={styles.successPage}>
        <h1 className={styles.success}>Votre abonnement a été activé avec succès. Vous pouvez maintenant accéder aux contenus exclusifs. Si vous n'avez pas accès de suite au contenu,
          veuillez vous déconnecter ou vous reconnecter. Si vous avez un problème, contactez-nous !! 
        </h1>
        <button onClick={() => navigate('/Lessons')}>Accéder aux cours</button>
    </div>
    
  )
}


