import { useState } from 'react';
import styles from './SignIn.module.scss';
import { useNavigate } from "react-router-dom";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';



export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);


  const navigate = useNavigate();
  
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const handleName = (e) => setName(e.target.value);
 
  

  const handleSignupSubmit = (e) => {
    e.preventDefault();
    // Create an object representing the request body
    const requestBody = { email, password, name };
 
    // Make an axios request to the API
    // If the POST request is a successful redirect to the login page
    // If the request resolves with an error, set the error message in the state
    axios.post(`${API_URL}/auth/signup`, requestBody)
      .then((response) => {
        navigate('/login');
      })
      .catch((error) => {
        const errorDescription = error.response.data.message;
        setErrorMessage(errorDescription);
      })
  };

 

  const handleGoogleSignIn = () => {
    // Logique pour s'inscrire avec Google (par exemple, utiliser Firebase ou OAuth)
    console.log("Google sign-in clicked");
  };

  return (
    <div className={styles.sign}>
    <div className={styles.signInContainer}>
      <h1 className={styles.h1}>S'inscrire</h1>
      <form className={styles.signInForm} onSubmit={handleSignupSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="fullName">Nom Complet:</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            required
            placeholder="Entrez votre nom complet"
            className={styles.inputField}
            value={name}
            onChange={handleName}
          />
          
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="Entrez votre email"
            className={styles.inputField}
            value={email}
            onChange={handleEmail}
          />
          
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password">Mot De Passe:</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            placeholder="Créez votre mot de passe"
            className={styles.inputField}
            value={password}
            onChange={handlePassword}
          />
          
        </div>
        
        <button type="submit" className={styles.signInButton}>
          S'inscrire
        </button>
      </form>
      { errorMessage && <p className="error-message">{errorMessage}</p> }
      {/* <div className={styles.orSeparator}>
        <span>OU</span>
      </div>
      <button className={styles.googleButton} onClick={handleGoogleSignIn}>
        <img src="google.png" alt="Google logo" className={styles.googleLogo} />
        S'inscrire avec Google
      </button> */}
    </div>
    </div>
  );
}


