import styles from './Footer.module.scss';
import { Link } from "react-router-dom";


function Footer() {
  return (
    <footer className={`footer ${styles.footer} `}>
      <div className={`footer1 ${styles.footer1}`}>

        <div className={styles.offers}>
        <Link to="/offers"><p>Offres Drill-it</p> </Link>
        </div>

        <div className={`info ${styles.info}`}>
          <div>
            <ul className={`ul ${styles.ul}`}>
              <li>
                <a href="https://www.instagram.com/drill_it_off/" target='_blank' rel="noopener noreferrer">
                  <i className={`fa-brands fa-instagram ${styles.fa}`}></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/drill-itoff" target='_blank' rel="noopener noreferrer">
                  <i className={`fa-brands fa-linkedin ${styles.fa}`}></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/drill_team_off/" target='_blank' rel="noopener noreferrer">
                  <i className={`fa-brands fa-instagram ${styles.fa}`}></i>
                </a>
                team
              </li>
            </ul>
          </div>
          <p className={`p-footer ${styles.pfooter}`}>© 2024 - Tous droits réservés drill-it.com</p>
          <div className={`cookie ${styles.cookie}`}>
            <p className={`pinfo ${styles.pinfo}`}>
              <a href="/CGVCGU1.pdf" target="_blank" rel="noopener noreferrer">
                  CGU/CGV
              </a>
            </p>

            <p className={`pinfo ${styles.pinfo}`}>Politique de confidentialité</p>
            <p className={`pinfo ${styles.pinfo}`}>Mentions Légales</p>
          </div>
        </div>

        <div className={styles.help}>
          <p>Besoin d'aide ?</p>
          <p className={`p15 ${styles.p15}`}><a className={`mail ${styles.mail}`} href="mailto:drill@drill-it.com">drill@drill-it.com</a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
