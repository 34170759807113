import React, { useState, useEffect } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

// Crée un contexte pour l'authentification
const AuthContext = React.createContext();

function AuthProviderWrapper(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  // Fonction pour stocker le token dans le localStorage
  const storeToken = (token) => {
    localStorage.setItem('authToken', token);
  };

  // Fonction pour récupérer le token et authentifier l'utilisateur
  const authenticateUser = () => {
    // Récupère le token stocké dans le localStorage
    const storedToken = localStorage.getItem('authToken');

    // Si le token existe
    if (storedToken) {
      // Envoie une requête avec le token pour vérifier l'utilisateur
      axios
        .get(`${API_URL}/auth/verify`, {
          headers: { Authorization: `Bearer ${storedToken}` }
        })
        .then((response) => {
          // Si la réponse du serveur est positive, mets à jour les variables d'état
          const user = response.data;
          setIsLoggedIn(true);
          setIsLoading(false);
          setUser(user);
        })
        .catch((error) => {
          // Si le token est invalide, mets à jour les variables d'état
          setIsLoggedIn(false);
          setIsLoading(false);
          setUser(null);
          console.error("Error verifying token:", error);
        });
    } else {
      // Si aucun token n'est trouvé, mets à jour les variables d'état
      setIsLoggedIn(false);
      setIsLoading(false);
      setUser(null);
    }
  };

  // Fonction pour retirer le token du localStorage (déconnexion)
  const removeToken = () => {
    localStorage.removeItem('authToken');
  };

  // Fonction de déconnexion de l'utilisateur
  const logOutUser = () => {
    // Supprime le token et réinitialise l'état
    removeToken();
    authenticateUser();
  };

  // Vérifie l'authentification à chaque montage du composant
  useEffect(() => {
    authenticateUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        user,
        storeToken,
        authenticateUser,
        logOutUser
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProviderWrapper, AuthContext };
