import { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import LessonData from '../../data/lesson.json';
import RenfoData from '../../data/renfo.json';
import styles from './MainNav.module.scss';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export default function MainNav({ basePath }) { // Passer basePath comme prop
  const { isLoggedIn, user, logOutUser, authenticateUser } = useContext(AuthContext); 
  const [searchQuery, setSearchQuery] = useState("");  
  const [searchResults, setSearchResults] = useState([]);  
  const [userData, setUserData] = useState(user); 
  const [showDropdown, setShowDropdown] = useState(false); 
  const searchResultsRef = useRef(null);  
  const navigate = useNavigate(); // Ajouter useNavigate

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const handleCancelAtPeriodEnd = async () => {
    if (!user || !user.subscription || !user.subscription.stripeSubscriptionId) {
      console.error("User or subscription information is missing.");
      alert("Impossible d'annuler l'abonnement, informations manquantes.");
      return;
    }

    try {
      const { data } = await axios.post(`${API_URL}/api/cancel-subscription`, {
        userId: user._id,
        subscriptionId: user.subscription.stripeSubscriptionId,
      });

      if (data.message) {
        alert('Votre abonnement sera annulé à la prochaine échéance. Vous avez reçu la confirmation par mail.');
        await authenticateUser(); 
      } else {
        alert("Erreur lors de la planification de l'annulation de l'abonnement.");
      }
    } catch (error) {
      console.error("Erreur lors de la tentative d'annulation de l'abonnement:", error);
      alert("Une erreur est survenue lors de l'annulation de votre abonnement.");
    }
  };

  useEffect(() => {
    authenticateUser(); 

    const handleClickOutside = (event) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
        setSearchQuery(""); 
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [authenticateUser]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  
    // Filtrer les données des leçons et ajouter la source
    const filteredLessons = LessonData.filter((lesson) =>
      lesson.title.toLowerCase().includes(query) || 
      lesson.description.toLowerCase().includes(query)
    ).map((lesson) => ({ ...lesson, source: 'Lessons' })); // Ajouter 'source' pour indiquer la catégorie
  
    // Filtrer les données de renfo et ajouter la source
    const filteredRenfo = RenfoData.filter((renfo) =>
      renfo.title.toLowerCase().includes(query) || 
      renfo.description.toLowerCase().includes(query)
    ).map((renfo) => ({ ...renfo, source: 'Renforcement' })); // Ajouter 'source' pour indiquer la catégorie
  
    // Fusionner les résultats
    const finalResults = [...filteredLessons, ...filteredRenfo];
  
    setSearchResults(finalResults);
  };
  

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCancelAtPeriodEndWithConfirmation = async () => {
    const confirmCancel = window.confirm("Êtes-vous sûr de vouloir annuler votre abonnement ?");
  
    if (confirmCancel) {
      await handleCancelAtPeriodEnd();
    } else {
      console.log("L'annulation de l'abonnement a été annulée.");
    }
  };

  return (
    <div className={styles.MainNav}>
      <div className={styles.nav}>
        <Link to="/"> <img src="/drill.jpg" alt="logo" /></Link>
        
        <form className={styles.searchForm}>
          <input
            type="text"
            placeholder="Rechercher un cours"
            className={styles.searchInput}
            value={searchQuery}
            onChange={handleSearch}
          />
          <button type="submit" className={styles.searchButton}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
              <path d="M22.75 11.375C22.75 13.8852 21.9352 16.2039 20.5625 18.0852L27.4859 25.0141C28.1695 25.6977 28.1695 26.8078
              27.4859 27.4914C26.8023 28.175 25.6922 28.175 25.0086 27.4914L18.0852 20.5625C16.2039 21.9406 13.8852 22.75 11.375
              22.75C5.09141 22.75 0 17.6586 0 11.375C0 5.09141 5.09141 0 11.375 0C17.6586 0 22.75 5.09141 22.75 11.375ZM11.375
              19.25C15.7227 19.25 19.25 15.7227 19.25 11.375C19.25 7.02734 15.7227 3.5 11.375 3.5C7.02734 3.5 3.5 7.02734 3.5
              11.375C3.5 15.7227 7.02734 19.25 11.375 19.25Z" fill="white"/>
            </svg>
          </button>
        </form>

        <div className={styles.links}>
          <Link to="/offers">Offres</Link>

          {isLoggedIn && (
            <>
              <div className={styles.profileMenu} onClick={toggleDropdown}>
                <span>{userData && userData.name}</span> 
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={styles.dropdownIcon}>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
                <div className={`${styles.dropdownMenu} ${showDropdown ? styles.show : ''}`}>
                  <ul>
                    {userData && userData.subscription && userData.subscription.isActive && !userData.subscription.isCancelledAtPeriodEnd && (
                      <li onClick={handleCancelAtPeriodEndWithConfirmation}>Annuler Abonnement</li>
                    )}
                    <li onClick={logOutUser}>Déconnexion</li>
                  </ul>
                </div>
              </div>
            </>
          )}
          {!isLoggedIn && (
            <>
              <Link to="/LogIn"> Connexion </Link>
              <Link to="/signup"> M'inscrire </Link>
            </>
          )}
        </div>
      </div>

      {searchQuery && (
        <div ref={searchResultsRef} className={styles.searchResultsContainer}>
          {searchResults.length > 0 ? (
            searchResults.map((item) => (
              <div 
                key={`${item.source}-${item.id}`} // Utiliser une clé unique
                className={styles.searchResultItem}
                onClick={() => navigate(`/${item.source}/${item.id}`)} // Utiliser la source pour définir `basePath`
              >
                {item.title} ({item.level}/{item.category ? item.category : "Renforcement"})
              </div>
            ))
          ) : (
            <p>Aucun résultat trouvé</p>
          )}
        </div>
      )}
    </div>
  );
}
