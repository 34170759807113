import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styles from './ResetPassword.module.scss'

export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post(`${API_URL}/auth/reset-password/${token}`, { password })
  .then((response) => {
    setMessage('Password has been reset.');
  })
  .catch((error) => {
    setMessage('Error resetting password.');
  });
  };

  return (
    <div className={styles.forgot}>
      <h1 className={styles.forgoth1}>Reset Password</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label className={styles.labelforgot}>New Password:</label>
        <input type="password" value={password} onChange={handlePasswordChange} required />
        <button type="submit"  className={styles.buttonforgot}>Reset password</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}
