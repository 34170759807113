import { loadStripe } from '@stripe/stripe-js';
import styles from "./Offers.module.scss";
import axios from 'axios';
import { AuthContext } from "../../context/auth.context";
import { useContext } from "react";

// Clé publique Stripe
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_TEST}`);
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
export default function Offers() {
  const { user } = useContext(AuthContext);

  const handleCheckout = async (priceId) => {
    
  
    if (!user) {
      console.error("User is not logged in.");
      alert("Vous devez être connecté pour procéder au paiement.");
      return;
    }
  
    const userId = user._id; // Assure-toi que le userId est bien défini
    try {
      const { data } = await axios.post(`${API_URL}/api/create-checkout-session`, {
        priceId,
        userId, // Envoie le userId et le priceId au backend
      });
  
      // Initialiser Stripe avec la session de paiement
      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });
  
      if (result.error) {
        console.error(result.error.message);
        alert("Erreur lors de la redirection vers Stripe : " + result.error.message);
      }
    } catch (error) {
      console.error("Erreur lors de la création de la session de paiement:", error);
      alert("Une erreur est survenue lors de la création de la session de paiement.");
    }
  };

  return (
    <div className={styles.offers}>
      <h1 className={styles.h1}>Découvrez nos Offres</h1>
      <div className={styles.offer}>
        <div className={styles.offerInfo}>
          <h2>Formule Mensuelle</h2>
          <p><span className={styles.price}>11,99€/mois</span>. <br /> <br />Offre flexible. <br /><br /> Sans engagement.</p>
          <button 
            className={styles.ctaButton} 
            onClick={() => handleCheckout('price_1Q19h4P5r9VIjX88kD2TIhQs')} // Utiliser l'ID du prix Stripe
          >
            Choisir cette formule
          </button>
        </div>

        <div className={styles.offerInfo} id={styles.OneYear}>
          <h2>Formule 6 mois</h2>
          <p><span className={styles.price}>10,99 € par mois</span>. <br /> <br />Payer 65,94 € pour 6 mois, économisez 12 €.</p>
          <ul>
            <li><strong>Économies : </strong>12 €</li>
            <li><strong>Durée : </strong>6 mois</li>
          </ul>
          <button 
            className={styles.ctaButton} 
            onClick={() => handleCheckout('price_1Q19i3P5r9VIjX88El5LGs8L')} // Utiliser l'ID du prix Stripe
          >
            Choisir cette formule
          </button>
        </div>

        <div className={styles.offerInfo}>
          <h2>Formule 1 an</h2>
          <p><span className={styles.price}>8,99 € par mois</span>. <br /> <br /> Payer 107,88 € pour 12 mois, économisez 48 €.</p>
          <ul>
            <li><strong>Économies : </strong>48 €</li>
            <li><strong>Durée : </strong>12 mois</li>
          </ul>
          <button 
            className={styles.ctaButton} 
            onClick={() => handleCheckout('price_1Q19jKP5r9VIjX88xX45YQFZ')} // Utiliser l'ID du prix Stripe
          >
            Choisir cette formule
          </button>
        </div>

        <div className={styles.offerInfo}>
          <h2>Formule 3 ans</h2>
          <p><span className={styles.price}>7,50 € par mois</span>. <br /> <br /> Payer 270 € pour 36 mois, économisez 197 €.</p>
          <ul>
            <li><strong>Économies : </strong>197 €</li>
            <li><strong>Durée : </strong>36 mois</li>
          </ul>
          <button 
            className={styles.ctaButton} 
            onClick={() => handleCheckout('price_1Q19kfP5r9VIjX88Mpe7N4dc')} // Utiliser l'ID du prix Stripe
          >
            Choisir cette formule
          </button>
        </div>
      </div>
    </div>
  );
}

