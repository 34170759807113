import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from './page/Homepage/HomePage';
import Footer from './components/Footer/Footer';
import ProfessorJJB1 from './page/Professors/ProfessorJJB1';
import NavBar from './components/NavBar/NavBar';
import MainNav from './components/MainNav/MainNav';
import LogIn from './page/LogIn/LogIn';
import SignIn from './page/SignIn/SignIn';
import Lessons from './page/LessonsPage/Lessons';
import LessonDetails from "./page/LessonDetails/LessonDetails";
import Lesson from "./data/lesson.json";
import styles from './App.module.scss';
import Offers from './page/Offers/Offers';
import ForgotPassword from './page/ForgotPassword/ForgotPassword';
import ResetPassword from './page/ResetPassword/ResetPassword';
import Success from './page/SuccessPage/SuccessPage';
import Error from './page/Error/Error';
import Gratuite from './page/Gratuites/Gratuite';
import Renforcement from './page/Renforcement/Renforcement';
import RenfoDetails from './page/RenfoDetails/RenfoDetails';

function App() {
  const [lessonToDisplay, setLessonToDisplay] = useState(Lesson[0]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Afficher le pop-up dès que l'utilisateur charge ou rafraîchit la page
    setShowPopup(true);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false); // Fermer le pop-up lorsque l'utilisateur clique sur le bouton
  };;

  return (
    <div className={` ${styles.appContainer}`}>
      <MainNav />
      <NavBar />

      {/* Pop-up qui s'affiche uniquement lors de la première visite */}
{showPopup && (
  <div className={styles.popup}>
    <div className={styles.fireworksContainer}>
      {/* Générer plusieurs feux d'artifice avec des positions aléatoires */}
      {Array.from({ length: 15 }).map((_, index) => (
        <div
          key={index}
          className={styles.firework}
          style={{
            '--random-x': Math.random(),
            '--random-y': Math.random(),
          }}
        ></div>
      ))}
    </div>
    <div className={styles.popupContent}>
      <p className={styles.popupPara}>
        <strong className={styles.strong}>20 nouvelles vidéos disponibles</strong> dans la catégorie <strong className={styles.strong}>JJB</strong>  !! <br /> <br />
        <strong className={styles.strong}>- 2 Débutants</strong> <br />
        <strong className={styles.strong}>- 13 Intermédiaires</strong> <br />
        <strong className={styles.strong}>- 6 Confirmés</strong> <br /> <br />
        Bon apprentissage !
      </p>
      <button className={styles.btnpop} onClick={handleClosePopup}>Fermer</button>
    </div>
  </div>
)}



      <div className={styles.content}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/ProfessorJJB1" element={<ProfessorJJB1 />} />
          <Route path="/gratuite" element={<Gratuite lessonToDisplay={lessonToDisplay} setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/renforcement" element={<Renforcement lessonToDisplay={lessonToDisplay} setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/renforcement/:id" element={<RenfoDetails lessonToDisplay={lessonToDisplay} setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/Lessons" element={<Lessons setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/Lessons/:id" element={<LessonDetails lessonToDisplay={lessonToDisplay} setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/LogIn" element={<LogIn />} />
          <Route path="/signup" element={<SignIn />} />
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;

