import Header from '../../components/Header/Header';
import MainNav from '../../components/MainNav/MainNav';
import styles from './HomePage.module.scss';

export default function Homepage() {
  return (
    <div className={`homepage ${styles.homepage}`} id='#homepage'>
      <Header />
    </div>
  );
}
